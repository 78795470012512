// import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import { IoBriefcase } from 'react-icons/io5';
import { GiGraduateCap } from 'react-icons/gi';
import FormalBox from "../components/FormalBox";
import FormalExperience from "../components/FormalExperience";

export default function Home() {
  const data = useStaticQuery(graphql`
    query allDataYaml {
      allDataYaml {
        edges {
          node {
            birth {
              year
              location
            }
            name {
              first
              middle
              last
            }
            position
          }
        }
      }
    }  
  `);

  const person = data.allDataYaml.edges[0].node;

  if(!person) return null;

  console.log('----> person: ', person);
  const experience = {
    "company": "Jobsity",
    "position": "Software Engineer",
    "timeperiod": "Since November 2019",
    "description": "Developing Full Stack solutions for companies in the USA like SignPost. Integrating with the Client's developers team using a mix of Scrum and Kanban methodologies to improve customer's outcomes by looking for solutions to reduce the churn and improve UX to keep users more comfortable using the product on along with providing value for them and putting my hand on technologies like React.js, Node.js, Express.js, JQuery, AWS (SNS, SQS, S3, Parameter Store, Lambda), Docker and Docker compose and for testing with Chai, Mocha, Puppeteer for E2E, Splunk.",
    "website": "https://www.jobsity.com"
  };

  return (
    <div className="resume">
      <div className="banner">
        <div className="banner__fullname">{person.name.first} {person.name.middle} {person.name.last}</div>
        <div className="banner__position">{person.position}</div>
        <div v-if="person.birth" className="banner__location">Born {person.birth.year} in {person.birth.location}</div>
      </div>

      <div className="content">
        <div className="content__left">
          <div className="section">
            <div className="section-headline">
              {/* {{ lang.about }} */}
            </div>

            <div className="section-content section-content--plain">
              {/* {{ person.about }} */}
              <br/>
              <br/>
              {/* {{ person.knowledge }} */}
            </div>
          </div>

          <div
            v-if="person.skills"
            className="section">
            <div className="section-headline">
              {/* {{ lang.skills }} */}
            </div>

            <div className="section-content-grid">
              <a
                v-for="(skill, index) in person.skills"
                className="grid-item"
                key="index"
                // className="{ link: skill.url !== undefined}"
                href="skill.url">
                <span className="squarred-grid-item">
                  {/* {{ skill.name }} */}
                </span>
              </a>
            </div>
          </div>

          <div className="section">
            <div className="section-headline">
              {/* {{ lang.contact }} */}
            </div>

            <div className="section-content section-content--plain">
              <div className="section-link">
                {/* <i className="section-link__icon material-icons">business</i>{{ person.contact.street }} */}
              </div>

              <a
                className="section-link link"
                href="contactLinks.email">
                {/* <i className="section-link__icon material-icons">mail</i>{{ person.contact.email }} */}
              </a>

              <div className="section-link">
                {/* <i className="section-link__icon material-icons">phone</i>{{ person.contact.phone }} */}
              </div>

              <a
                v-if="person.contact.website"
                className="section-link link"
                href="person.contact.website">
                {/* <i className="section-link__icon fa fa-globe"></i>{{ person.contact.website }} */}
              </a>

              <a
                v-if="person.contact.linkedin"
                className="section-link link"
                href="contactLinks.linkedin">
                {/* <i className="section-link__icon fa fa-linkedin"></i>{{ person.contact.linkedin }} */}
              </a>

              <a
                v-if="person.contact.github"
                className="section-link link"
                href="contactLinks.github">
                {/* <i className="section-link__icon fa fa-github"></i>{{ person.contact.github }} */}
              </a>

              <a
                v-if="person.contact.medium"
                className="section-link link"
                href="contactLinks.medium">
                {/* <i className="section-link__icon fa fa-medium"></i>{{ person.contact.medium }} */}
              </a>
            </div>
          </div>
        </div>

        <div className="content__right">
          <div className="section">
            <div className="section-headline">
              {/* <i className="section-headline__icon material-icons">work</i>{{ lang.experience }} */}
            </div>

            <div className="section-content">
              <a
                v-for="(experience, index) in person.experience"
                key="index"
                className="section-content__item"
                // className="{ link: experience.website !== undefined}"
                href="experience.website">

                {/* <span className="section-content__header">{{ experience.position }}</span> */}
                <span className="section-content__subheader">
                  {/* {{ experience.company }} */}
                  {/* <span className="section-content__plain">{{ experience.location }}</span> */}
                </span>

                {/* <div className="section-content__text">{{ experience.timeperiod }}</div> */}
                {/* <span className="section-content__text--light">{{ experience.description }}</span> */}
              </a>
            </div>
          </div>

          <div className="section">
            <div className="section-headline">
              {/* <i className="section-headline__icon material-icons">school</i>{{ lang.education }} */}
            </div>

            <div className="section-content">
              <a
                v-for="(education, index) in person.education"
                className="section-content__item"
                key="index"
                // className="{ link: education.website !== undefined}"
                href="education.website">

                {/* <span className="section-content__header"> {{ education.school }} </span>
                <span className="section-content__subheader">{{ education.degree }}</span>
                <span className="section-content__text"> {{ education.timeperiod }} </span>
                <span className="section-content__text--light"> {{ education.description }} </span> */}
              </a>
            </div>
          </div>

          <div
            v-if="person.projects"
            className="section">
            <div className="section-headline">
              {/* <i className="section-headline__icon material-icons">code</i>{{ lang.projects }} */}
            </div>

            <div className="section-content-grid">
              <a v-for="(project, index) in person.projects" key="index"
                className="section-content__item-grid"
                // className="{ link: project.url !== undefined}"
                href="project.url">
                {/* <span className="section-content__header"> {{ project.name }} </span>
                <span className="section-content__subheader">{{ project.platform }}</span>
                <span className="section-content__text"> {{ project.description }} </span> */}
              </a>
            </div>
          </div>

          <div
            v-if="person.contributions"
            className="section">
            <div className="section-headline">
              {/* <i className="section-headline__icon fa fa-heart"></i>{{lang.contributions}} */}
            </div>

            <div className="section-content-grid">
              <a
                v-for="(contribution, index) in person.contributions"
                className="section-content__item-grid"
                key="index"
                // className="{ link: contribution.url !== undefined}"
                href="contribution.url">
                {/* <span className="section-content__header"> {{ contribution.name }} </span>
                <span className="section-content__text"> {{ contribution.description }} </span> */}
                <span className="section-content__text--light"
                  // style="word-break: break-all;"
                >
                  {/* {{ contribution.url }} */}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <img className="picture"/>
    </div>
  );

  // return (
  //   <div className="max-w-5xl mx-auto font-sans">
  //     <main className="max-w-full mx-auto">
  //       <header className="bg-banner-color text-white py-6 px-4 sm:px-6 lg:px-8">
  //         <h1 className="text-2xl lg:text-4xl leading-tight lg:leading-normal">{person.name.first} {person.name.middle} {person.name.last}</h1>
  //         <h3 className="text-sm lg:text-lg font-light leading-none lg:leading-5">{person.position}</h3>
  //         <h4 className="text-xs lg:text-base font-extralight leading-5">Born {person.birth.year} in {person.birth.location}</h4>
  //       </header>
  //       <div className="grid grid-cols-1 grid-rows-2 md:grid-cols-5 md:grid-rows-1">
  //         <div className="row-start-2 md:row-start-1 bg-accent-color md:col-start-1 md:col-end-3 py-6 px-4 sm:px-6 lg:px-8">
  //           About me
  //         </div>
  //         <div className="md:col-start-3 md:col-end-12 py-6 px-4 sm:px-6 lg:px-8">
  //           <FormalBox icon={<IoBriefcase className="w-6 md:w-8 h-6 md:h-8" />} title='Experience'>
  //             <FormalExperience {...experience} />
  //           </FormalBox>
  //           <FormalBox icon={<GiGraduateCap className="w-6 md:w-8 h-6 md:h-8" />} title='Education'>
  //             Me
  //           </FormalBox>
  //         </div>
  //       </div>
  //     </main>
  //   </div>
  // )
}
